import '../../css/index.css'
import '../../css/common.css'
import ScrollToTop from '../../components/ScrollToTop'
import NavigationBar from '../../components/NavigationBar'
import Footer from '../../components/Footer'
import HeroSection from './HeroSection'
import AboutSection from './AboutSection'
// import MineSection from './MineSection'
import VideoSection from './VideoSection'
import InfoSection from './InfoSection'
import FeaturesSection from './FeaturesSection'
import FAQsSection from './FAQsSection'
import BottomTextView from '../../../landing-page/BottomTextView'

export default function LandingPage() {
  const bgImgs: string[] = [
    '/landing-page-hero-section-bg.webp',
    '/landing-page-hero-section-bg-2.webp',
    '/landing-page-hero-section-bg-3.webp',
    '/landing-page-hero-section-bg-4.webp',
  ]
  return (
    <div className='revamped-wrapper'>
      <ScrollToTop />

      <NavigationBar />

      <main className='revamped-landing-page'>
        <HeroSection bgImgs={bgImgs} />
        <AboutSection />
        {/* <MineSection /> */}
        <VideoSection />
        <InfoSection />
        <FeaturesSection />
        <FAQsSection />
        {/* <BottomTextView /> */}
      </main>

      <Footer />
    </div>
  )
}

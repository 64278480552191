import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../hooks'
import { PhoneWeb3Login, WalletConnectLogin, WalletModalConnectLogin, Web3Login } from '../../../landing-page/Web3Login'
import { useDeviceDetector } from '../../../utils/device_detect'
import landingPageHeroSectionImg1 from '../../assets/images/landing-page-hero-section-1.webp'

import { Modal, Box, Button, Typography } from '@mui/material'
import { Google as GoogleIcon } from '@mui/icons-material' // If you have Google icon or use an image
import metamaskLogo from '../../../revamped/assets/images/metamask.svg'
import googleLogo from '../../../revamped/assets/images/google.svg'
import { GoogleLogin } from '@react-oauth/google'
import { useGoogleLogin } from '@react-oauth/google'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';


import { jwtDecode } from 'jwt-decode'
import store from '../../../stores'
import { SetFailureNotificationBool, SetFailureNotificationMessage } from '../../../stores/NotificationStore'
import { ChangeLoggerMessage } from '../../../stores/UserWebsiteStore'

import MetaMaskOnboarding from '@metamask/onboarding'
import AnimatedLoader from '../../FullScreenLoader/AnimatedLoader'
import { Capacitor } from '@capacitor/core'
import detectEthereumProvider from "@metamask/detect-provider";
import { checkIpad, getSystemInfo, isDesktopBrowser } from '../../../utils/systemInfo'

interface IHeroSection {
  bgImgs: Array<string>
}

export default function HeroSection(props: IHeroSection) {
  const [showConnectBtn, setShowConnectBtn] = useState(true)
  const web2Address = useAppSelector((state) => state.web3store.web2EmailAddress)
  const userAddress = useAppSelector((state) => state.web3store.userAddress)
  const metaMaskInstalledBool = useAppSelector((state) => state.userPathStore.metaMaskInstalled)

  const [isLoading, setIsLoading] = useState(false)

  const onboarding = new MetaMaskOnboarding()

  const selectedNetwork = useAppSelector((state) => state.web3store.web3Network)

  const { isMobile, isBrowser, isTablet } = useDeviceDetector()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    console.log('particle --- ', userAddress, localStorage.getItem('web2_wallet_address'))
    if (userAddress) {
      setShowConnectBtn(false)
    }
  }, [web2Address, userAddress])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleMetaMaskLogin = () => {
    console.log('MetaMask login flow triggered')
    connectButtonHandle()
  }

  const handleGoogleLoginClick = async () => {
    console.log("inside login flow --- ", Capacitor.isNativePlatform())
    if (Capacitor.isNativePlatform()) {
      if (Capacitor.isNativePlatform()) {
        // Mobile (Capacitor) flow
        const googleUser = await GoogleAuth.signIn();
        const userInfo = googleUser;
        await handleGoogleLoginV2(userInfo);
      }
    } else {
      login();
    }
  };


  const login = useGoogleLogin({
    onSuccess: async (response) => {
      // console.log('Login successful:', response);
      setIsLoading(true)

      const userInfo = await fetchUserInfo(response.access_token)
      await handleGoogleLoginV2(userInfo)

      setIsLoading(false)
      // Send the response to your backend or use it in your app
    },
    onError: () => {
      console.error('Login Failed')
    },
  })

  const fetchUserInfo = async (accessToken: string) => {
    try {
      const response = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      const data = await response.json()
      return data
    } catch (error) {
      console.error('Error fetching user info:', error)
    }
  }

  const handleGoogleLoginV2 = async (decoded: any) => {
    if (decoded?.email) {
      localStorage.setItem('web2_wallet_address', decoded.email)
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
    // setIsLoading(false)
  }


  const connectButtonHandle = async () => {
    setIsLoading(true)
    const selectedNetwork = localStorage.getItem("network_connected")
    console.log('click connectButtonHandle -- ', selectedNetwork)
    handleClose()

    if (selectedNetwork == '') {
      store.dispatch(SetFailureNotificationBool(true))
      store.dispatch(SetFailureNotificationMessage('Connect Wallet'))
      setIsLoading(false)
      return
    }

    const provider = await detectEthereumProvider();
    if (provider || window.ethereum) {
      console.log("Ethereum successfully detected!");
      if (metaMaskInstalledBool) {
        // await Web3Login()
        setIsLoading(false)
        await WalletModalConnectLogin()
      }
    } else {
      console.error("Please install MetaMask!");
      if (Capacitor.isNativePlatform()) {
        setIsLoading(false)
        await WalletModalConnectLogin()

      } else if (getSystemInfo() || checkIpad()) {
        //
        setIsLoading(false)
        // await WalletModalConnectLogin()
        // await WalletModalConnectLogin()
        window.location.href = "https://metamask.app.link/dapp/https://dev.bitfighters.club";
      } else if (isDesktopBrowser()) {
        //
        onboarding.startOnboarding()
      } else {
        // not sure what to do
      }
    }
    setIsLoading(false)
    handleClose()
  }

  function connectModalOpen() {
    console.log('press connect ---> ', isMobile, isBrowser, isTablet)
    setOpen(true)
  }

  return (
    <>
      {isLoading && <AnimatedLoader />}
      <section
        id='home'
        className='hero-section'
        style={{
          backgroundImage: `radial-gradient(50% 50% at 50% 50%, rgba(4, 10, 22, 0.8) 0%, rgba(4, 10, 22, 0) 75.5%), url('${props.bgImgs[Math.floor(Math.random() * props.bgImgs.length)]
            }')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center 75%',
        }}
      >
        <div className='top-fader'></div>

        <div className='container'>
          <img src={landingPageHeroSectionImg1} alt='' />

          <div className='btns-wrapper'>
            <Link to={'/game'} className='primary-btn-component'>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>

              <div className='content'>
                <span>Play now</span>
              </div>
            </Link>

            {showConnectBtn && (
              <Box onClick={() => connectModalOpen()} className='primary-btn-component'>
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
                <div className='content'>
                  <span>Connect</span>
                </div>
              </Box>
            )}

            {/* <img
            src={"/assets/bitboy_logo.webp"}
            style={{
              width: '50px', height: '80px'
            }}
          /> */}
            {/* <img src={"/assets/bitboy_logo.webp"} alt="webp_logo" /> */}

            {/* <a href='#video' className='play-btn'>
            <svg width='42' height='43' viewBox='0 0 42 43' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <circle cx='21' cy='21.3712' r='21' fill='white' fillOpacity='0.1' />
              <circle cx='21' cy='21.3712' r='20.5' stroke='white' strokeOpacity='0.1' />
              <g clipPath='url(#clip0_233_107)'>
                <path
                  d='M26.9173 21.3296V22.4129H26.3757V22.9546H25.834V23.4962H24.7507V24.0379H23.6673V24.5796H23.1257V25.1212H22.0423V25.6629H20.959V26.2046H20.4173V26.7462H19.334V27.2879H18.2507V27.8296H16.6257V27.2879H16.084V16.4546H16.6257V15.9129H18.2507V16.4546H19.334V16.9962H20.4173V17.5379H20.959V18.0796H22.0423V18.6212H23.1257V19.1629H23.6673V19.7046H24.7507V20.2462H25.834V20.7879H26.3757V21.3296H26.9173Z'
                  fill='white'
                />
              </g>
              <defs>
                <clipPath id='clip0_233_107'>
                  <rect width='13' height='13' fill='white' transform='translate(15 15.3712)' />
                </clipPath>
              </defs>
            </svg>

            <p>Gameplay</p>
          </a> */}
          </div>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='login-modal-title'
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)', // Darker backdrop
            },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              borderRadius: '16px',
              border: '4px solid black',
              boxShadow: 24,
              p: 4,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              gap: '40px',
            }}
            className='cursor-pointer'
          >
            <img src={metamaskLogo} alt='MetaMask Logo' style={{ width: 50, height: 50 }} onClick={handleMetaMaskLogin} />
            <img src={googleLogo} alt='MetaMask Logo' style={{ width: 50, height: 50 }} onClick={() => handleGoogleLoginClick()} />
          </Box>
        </Modal>
      </section>
    </>
  )
}
